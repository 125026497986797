import { useState, useEffect } from "react"
import ICustomer from "types/ICustomer"

export const useShowNotification = (user: ICustomer, verifyCookie: boolean) => {
    const [showNotification, setShowNotification] = useState<boolean>(false)

    useEffect(() => {
        if (!user) {
            setShowNotification(false)
        } else {
            const isUserVerified =
                user?.verification?.is_email_verified &&
                user?.verification?.is_phone_verified
            setShowNotification(!verifyCookie && !isUserVerified)
        }
    }, [user, verifyCookie])

    return { showNotification, setShowNotification }
}
