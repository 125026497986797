import axios from "axios"

export const signup = async (userData: any) => {
    try {
        const { data } = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}users/signup`,
            userData
        )
        return data
    } catch (err: any) {
        if (err.response.data.message) {
            throw err.response.data.message
        } else if (err.response.data.errors) {
            throw err.response.data.errors[0].split(`"`)[1]
        } else {
            throw "something is wrong with response, in fetchAuth.ts"
        }
    }
}
