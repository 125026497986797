import { useState, useRef, SetStateAction, useEffect } from 'react';
import Image from 'next/image';
import { useMutation } from '@tanstack/react-query';
import Otp from 'components/atoms/Otp/Otp';
import Timer from 'components/atoms/Timer/Timer';
import { requestOtp } from 'helpers/requestOtp';
import Link from 'next/link';
import { useUserContext } from 'context/UserContext';
import { useLang } from 'hooks/useLang';
import Button from 'components/Form/Button';
import RoundedXIcon from 'components/icons/RoundedX';
import Input from 'components/Form/Input';
import Modal from 'components/Modal/Modal';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  email: string;
  phone: string;
  callingCode: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

const VerifyAccModal = ({ setIsModalOpen, isModalOpen, email, phone, callingCode, isEmailVerified, isPhoneVerified }: IProps) => {
  const { t } = useLang();
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [showResend, setShowResend] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [verifyInputsFilled, setVerifyInputsFilled] = useState(false);

  const [formType, setFormType] = useState('');

  // Close modal on click outside
  const bgRef = useRef(null);

  window.addEventListener('click', (e) => {
    if (e.target === bgRef.current) {
      setIsModalOpen(false);
    }
  });

  useEffect(() => {
    if (otp.join('').length === 4) {
      setVerifyInputsFilled(true);
    } else {
      setVerifyInputsFilled(false);
    }
  }, [otp]);

  const { mutate: sendOtp } = useMutation({
    mutationFn: requestOtp,
  });

  const { verifyAccount } = useUserContext();

  const { mutate, isPending: verifyIsPending, data: verifyAccountData } = verifyAccount;

  useEffect(() => {
    if (verifyAccountData?.message === 'invalidOTP') {
      setOtpError(true);
    }
    if (verifyAccountData?.message === 'verified') {
      setOtpError(false);
      setIsModalOpen(false)
    }
  }, [verifyAccountData?.message]);

  const handleOtp = () => {
    setShowOtpPage(true);

    if (formType === 'email') {
      sendOtp({
        value: email,
        inputType: 'email',
      });
    }
    if (formType === 'phone') {
      sendOtp({
        value: phone,
        inputType: 'phone',
        callingCode,
      });
    }
  };

  const handleVerifyOtp = () => {
    if (formType === 'email') {
      mutate({
        email,
        otp: otp.join(''),
      });
    }

    if (formType === 'phone') {
      mutate({
        callingCode,
        phone,
        otp: otp.join(''),
      });
    }
  };

  const sendOtpCodeAgain = () => {
    // Reset the states
    setShowResend(false);
    setOtp((otp) => otp.fill(''));

    // Request otp
    handleOtp();
  };

  const showUserInfo = () => {
    if (formType === 'email') {
      return (
        <p className="text-sm lowercase">
          {t('we_sent_otp')} {email.split('@')[0].slice(0, 3)}******@
          {email.split('@')[1]}
        </p>
      );
    }
    if (formType === 'phone') {
      return (
        <p className="text-sm lowercase">
          {t('we_sent_otp_phone')} <span className={`font-bold`}>{phone.slice(-3)}</span>
        </p>
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      Close={() => setIsModalOpen(false)}
      className="bg-white dark:bg-primary-theme-500"
    >
      <div className="relative flex w-full flex-col gap-5 rounded-md bg-white p-10 capitalize dark:bg-primary-theme-500">
        <div className="absolute right-2 top-2 flex cursor-pointer items-center justify-center overflow-hidden rounded-full bg-white p-1 text-xl font-bold dark:bg-transparent">
          <RoundedXIcon onClick={() => setIsModalOpen(false)} />
        </div>
        <div className="flex items-center justify-center">
          <Image
            src={'/svg/verify_modal.svg'}
            alt="transfer-image"
            width={160}
            height={120}
          />
        </div>

        <h2 className="text-center text-base font-medium text-action-theme-400 dark:text-action-theme-400 md:text-2xl">{t('verify')}</h2>

        {showOtpPage ? (
          <div className="flex flex-col gap-4 text-center text-black dark:text-white">
            <p className="text-sm text-black dark:text-white">{showUserInfo()}</p>

            <Otp
              otp={otp}
              setOtp={setOtp}
              width="w-12"
              height="h-12"
              isFilled={verifyInputsFilled}
              isError={otpError}
            />

            {showResend ? (
              <div className="mt-2 text-center">
                <p className="text-sm text-black dark:text-white">
                  {t('got_the_code')}
                  <span
                    onClick={sendOtpCodeAgain}
                    className="mx-2 text-blue-500 hover:cursor-pointer hover:underline dark:text-blue-400"
                  >
                    {t('resend_code')}
                  </span>
                </p>
              </div>
            ) : (
              <div className={`my-2 text-center`}>
                <p className="inline-block text-sm text-blue-500 dark:text-blue-400">
                  <Timer
                    initialMinute={1}
                    initialSeconds={30}
                    setShowResend={setShowResend}
                    center
                  />
                </p>
              </div>
            )}

            <Button
              text={t('verify')}
              //className="mt-5 text-xs md:text-base"
              className="w-full rounded-full px-4 py-3 text-sm font-bold text-white transition-all md:px-6"
              type="submit"
              disabled={!verifyInputsFilled}
              loading={verifyIsPending}
              onClick={handleVerifyOtp}
            />
          </div>
        ) : (
          <>
            <p className="text-center text-sm text-black dark:text-white md:text-base">{t('verify_modal_msg')}</p>

            <form
              className="flex flex-col gap-2"
              onSubmit={handleOtp}
            >
              {/* Radio */}
              <div className="flex items-center gap-2">
                <div className={`flex w-full flex-col gap-2 text-black dark:text-white`}>
                  {!isEmailVerified && (
                    <Input
                      id="email-verify"
                      label={t('verify_by_email')}
                      name="email"
                      type="radio"
                      value="email"
                      onChange={(e: {
                        target: {
                          value: SetStateAction<string>;
                        };
                      }) => setFormType(e.target.value)}
                      checked={formType === 'email'}
                      required={false}
                    />
                  )}
                  {!isPhoneVerified && (
                    <Input
                      id="phone-verify"
                      label={t('verify_by_phone')}
                      name="phone"
                      type="radio"
                      value="phone"
                      onChange={(e: {
                        target: {
                          value: SetStateAction<string>;
                        };
                      }) => setFormType(e.target.value)}
                      checked={formType === 'phone'}
                      required={false}
                    />
                  )}
                </div>
              </div>

              {/* Phone input */}
              {formType === 'phone' && (
                <div className="mt-2">
                  <label
                    htmlFor="phoneInput"
                    className={`mb-1 block w-full text-black dark:text-white`}
                  >
                    {t('phone')}
                  </label>
                  <div className="flex items-center gap-2">
                    <div className="flex w-16 rounded-md border border-gray-200 bg-gray-100 p-2 text-center shadow-sm transition-colors duration-300 ease-in-out dark:border-dark_light_gray dark:bg-primary-theme-500 dark:text-white">
                      <p>+</p>
                      <input
                        value={callingCode}
                        type="text"
                        placeholder={'966'}
                        maxLength={3}
                        className="w-full bg-transparent"
                        readOnly
                      />
                    </div>
                    <input
                      value={phone}
                      id="phoneInput"
                      maxLength={8}
                      type="text"
                      placeholder={t('phoneNumber')}
                      className="w-full rounded-md border border-gray-200 bg-gray-100 p-2 shadow-sm transition-colors duration-300 ease-in-out dark:border-dark_light_gray dark:bg-primary-theme-500 dark:text-white"
                      readOnly
                    />
                  </div>
                  <div className="mt-2 text-end text-blue-500">
                    <Link
                      href={'/user/profile/account'}
                      legacyBehavior
                    >
                      {t('change_phone_text')}
                    </Link>
                  </div>
                </div>
              )}

              {/* Email input */}
              {formType === 'email' && (
                <div className={`mt-2`}>
                  <label
                    htmlFor="emailInput"
                    className={`mb-1 block w-full dark:text-white`}
                  >
                    {t('email')}
                  </label>
                  <input
                    value={email}
                    id="emailInput"
                    name="email"
                    type="email"
                    placeholder={t('pl_email')}
                    className="w-full rounded-md border border-gray-200 bg-gray-100 p-2 shadow-sm dark:border-dark_light_gray dark:bg-primary-theme-500 dark:text-white"
                    readOnly
                  />
                  <div className="mt-2 text-end text-blue-500">
                    <Link
                      href={'/user/profile/account'}
                      legacyBehavior
                    >
                      {t('change_email_text')}
                    </Link>
                  </div>
                </div>
              )}

              <Button
                text={t('verify')}
                //className="mt-5 text-xs md:text-base"
                className="w-full rounded-full px-4 py-3 text-sm font-bold text-white transition-all md:px-6"
                type="submit"
                loading={verifyIsPending}
                disabled={!formType}
              />
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default VerifyAccModal;
