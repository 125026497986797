import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useLang } from "hooks/useLang"
import Button from "components/Form/Button"
import { IoMdClose } from "react-icons/io"
import Image from "next/image"
import useScrollTop from "hooks/useScrollTop"

const setExpireCookie = (days: number) => {
    const now = new Date()
    const time = now.getTime()
    now.setTime(time + days)
    const expiringDate = now.toUTCString()
    document.cookie =
        "userBanner=expiredBanner; expires=" + expiringDate + ";path=/"
}

const useCookie = () => {
    const [checkedCookie, setCheckCookie] = useState(false)

    useEffect(() => {
        const userBanner = /userBanner/i.test(document.cookie)
        setCheckCookie(!userBanner)
    }, [])

    return [checkedCookie, setCheckCookie]
}

const SmartBanner = () => {
    const { t } = useLang()
    const router = useRouter()
    const [isOpen, setOpen] = useState(false)
    const [isIphone, setIphone] = useState(false)
    const [checkedCookie, setCheckCookie] = useCookie()

    const scrollTop = useScrollTop()

    useEffect(() => {
        const isClientIphone = /iPad|iPhone/i.test(window.navigator.userAgent)
        setIphone(isClientIphone)
    }, [])

    const redirectUrl = () => {
        setExpireCookie(1000 * 60 * 60 * 24 * 7)

        const url = isIphone
            ? "https://apps.apple.com/sa/app/pass-tickets/id1596912352?ign-itscg=30200&ign-itsct=apps_box_link"
            : "https://play.google.com/store/apps/details?id=com.starthub.passtickets"

        router.push(url)
    }

    const closeBanner = () => {
        setOpen(true)
        setExpireCookie(1000 * 60 * 60 * 24 * 3)
    }

    useEffect(() => {
        if (scrollTop > 600) {
            closeBanner()
        }
    }, [scrollTop])

    // Hide banner in booking, payment, or invoice pages
    useEffect(() => {
        if (
            router.pathname.includes("/book") ||
            router.pathname.includes("/payment") ||
            router.pathname.includes("/order-status")
        ) {
            setOpen(true)
        }
    }, [router.pathname])

    if (!isOpen && checkedCookie) {
        return (
            <div className="fixed top-0 z-[99] flex h-[70px] w-full items-center gap-2 bg-white px-[8px] py-[11px] shadow-md dark:bg-primary-theme-500 md:hidden">
                <IoMdClose className="h-8 w-8 p-1" onClick={closeBanner} />
                <Image
                    src={"/iconx/android-chrome-512x512.png"}
                    alt="PassTickets-logo"
                    style={{ objectFit: "contain", borderRadius: 16 }}
                    width={50}
                    height={50}
                />

                <div className="flex flex-1 items-center font-medium">
                    <p className="text-sm">{t("smartBanner-useApp")}</p>
                </div>
                <Button onClick={redirectUrl} className="px-4 py-1 text-sm">
                    {t("smartBanner-view")}
                </Button>
            </div>
        )
    }

    return null
}

export default SmartBanner
