import * as React from "react"

const Loading = (props: any, color: string = "#fff") => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
            margin: "auto",
            background: "transparent",
            display: "block",
            shapeRendering: "auto",
        }}
        width={24}
        height={24}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        {...props}
    >
        <circle
            cx={50}
            cy={50}
            fill="none"
            stroke={props.color ?? "#fff"}
            strokeWidth={10}
            r={35}
            strokeDasharray="164.93361431346415 56.97787143782138"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
            />
        </circle>
    </svg>
)

export default Loading
