import dayjs from 'dayjs';
import { ISelectedTicket } from 'types/improved-booking';
import { ITicket } from 'types/ticket';

const isTimeslotEligible = (ticket: ITicket): boolean => {
  const currentDate = dayjs();
  const endDate = dayjs(ticket.end);

  if (!currentDate.isBefore(endDate)) {
    return false;
  }

  if (ticket.status !== 'active') {
    return false;
  }

  return true;
};

export const getAvailableTimeslots = (tickets: ITicket[]): ITicket[] => {
  return tickets
    .map((ticket) => {
      if (isTimeslotEligible(ticket)) {
        return { ...ticket };
      }

      return null;
    })
    .filter((ticket) => ticket !== null)
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
};

export const filterTicketsByTimeslotId = (timeslotId: string, tickets: ITicket[]) => {
  return tickets.find((ticket) => ticket.timeslot_id === timeslotId)?.types || [];
};

export const isCountCurrentlyQualifiedForPromotion = (ticketType: ISelectedTicket, currentTicketCount: number): boolean => {
  const promotionStartCount = ticketType.promotion?.buy_qty || 0;
  const promotionEndCount = promotionStartCount + (ticketType.promotion?.get_qty || 0);

  if (ticketType.promotion && currentTicketCount >= promotionStartCount && currentTicketCount < promotionEndCount) {
    const fullPromotionSets = Math.floor(currentTicketCount / promotionStartCount);
    return fullPromotionSets > 0;
  }

  return false;
};
