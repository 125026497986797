import { useEffect } from "react"
import { useRouter } from "next/router"
import * as gtag from "../../../lib/gtag"

export interface EventsIntegrationData {
    eventId: string
    pixelId: string
    eventName: string
    tiktokPixelId?: string
    snapPixelId?: string
}

interface Props {
    pixelId?: string
    pageView?: boolean
    children: React.ReactNode
    eventId?: string
    trackData?: (title: string, data?: any) => void
}

const PixelIntegrationComponent = ({ children }) => {
    const router = useRouter()
    const currentPath = router.asPath

    const mainPixel = process.env.NEXT_PUBLIC_PASSTICKETS_FACEBOOK_PIXEL_KEY

    const currentGuvaliId = process.env.NEXT_PUBLIC_CURRENT_GUVALI_ID

    const guvaliId = process.env.NEXT_PUBLIC_GUVALI_ID
    const guvaliPixel = process.env.NEXT_PUBLIC_GUVALI_FACEBOOK_PIXEL_KEY

    const integrationsData: Array<EventsIntegrationData> = [
        { eventName: "guvali", eventId: currentGuvaliId, pixelId: guvaliPixel },
        { eventName: "guvali", eventId: guvaliId, pixelId: guvaliPixel },
        {
            eventName: "ghabga",
            eventId: process.env.NEXT_PUBLIC_GHABGA_EVENT_ID,
            pixelId: process.env.NEXT_PUBLIC_GHABGA_FACEBOOK_PIXEL_KEY,
            snapPixelId: process.env.NEXT_PUBLIC_GHABGA_SNAPCHAT_PIXEL_KEY,
            tiktokPixelId: process.env.NEXT_PUBLIC_GHABGA_TIKTOK_PIXEL_KEY,
        },
        {
            eventName: "billory",
            eventId: "66eaa9f10c3c0a69b8ab51c9",
            pixelId: process.env.NEXT_PUBLIC_BILLORY_PIXEL_KEY!,
        },
    ]

    const eventData = integrationsData.find((event) =>
        currentPath.includes(event.eventId)
    )

    useEffect(() => {
        if (window !== undefined) {
            import("react-facebook-pixel")
                .then((x) => x.default)
                .then((ReactPixel) => {
                    ReactPixel.init(mainPixel)
                    ReactPixel.pageView()

                    const eventData = integrationsData.find((event) =>
                        currentPath.includes(event.eventId)
                    )

                    if (eventData?.pixelId) {
                        ReactPixel.init(
                            process.env
                                .NEXT_PUBLIC_PASSTICKETS_FACEBOOK_PIXEL_KEY
                        )
                        ReactPixel.pageView()
                    }

                    const handleRouterChange = (url: string) => {
                        gtag.pageView(url)
                        ReactPixel.pageView()
                    }

                    router.events.on("routeChangeComplete", handleRouterChange)
                    return () => {
                        router.events.off(
                            "routeChangeComplete",
                            handleRouterChange
                        )
                    }
                })
        }
    }, [router.events, currentPath])

    return <>{children}</>
}

export default PixelIntegrationComponent
