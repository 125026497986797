import React, { ReactElement, useState } from "react"
import EyeHideIcon from "../icons/EyeHideIcon"
import EyeIcon from "../icons/EyeIcon"
import { useLang } from "hooks/useLang"

interface IProps {
    type?: "text" | "email" | "password" | "date" | "radio" | "tel" | "number"
    label?: string
    value?: string
    placeholder?: string
    name?: string
    children?: ReactElement
    onChange?: Function
    onBlur?: Function
    onClick?: Function
    onKeyPress?: Function
    required?: boolean
    requiredMsg?: string
    error?: string | any
    disabled?: boolean
    additionalInfo?: string
    className?: string
    autoCorrect?: boolean
    full?: boolean
    checked?: boolean
    divStyle?: string
    minLength?: number
    maxLength?: number
    id?: string | number | any
}

const Input = ({
    type = "text",
    label = "lable",
    value = "",
    placeholder = "Placeholder",
    name = "name",
    onChange = () => {},
    onBlur = () => {},
    onClick = () => {},

    required = true,
    requiredMsg = "",
    error = "",
    disabled = false,
    className = "",
    checked = false,
    divStyle = "",
    maxLength,
    minLength,
    id,
}: IProps) => {
    const { isAr } = useLang()

    const [showPassword, setShowPassword] = useState(false)

    // Function for input type
    const setType = () => {
        if (type === "password" && showPassword) {
            return "text"
        } else {
            return type
        }
    }

    const input = (
        <div className="relative">
            <input
                id={id}
                className={className + " " + "px-4"}
                type={setType()}
                value={value}
                placeholder={placeholder}
                name={name}
                required={required}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e)}
                onClick={(e) => onClick(e)}
                // onKeyPress={(e) => onClick(e)} // onKeyPress is deprecated
                disabled={disabled}
                checked={checked}
                maxLength={maxLength}
                minLength={minLength}
                autoComplete="on"
            />
            {type === "password" && (
                <div
                    className={`absolute ${
                        isAr ? "left-1" : "right-1"
                    } top-1/2 flex h-[80%] w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-md bg-gray-100 dark:bg-dark_gray`}
                    onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ? <EyeIcon /> : <EyeHideIcon />}
                </div>
            )}
        </div>
    )

    if (type === "radio") {
        return (
            <div className={"relative flex  gap-2" + (error && "error")}>
                {input}
                <label htmlFor={id}>{`${label}`}</label>

                <span className="absolute top-[80%] text-red-500 dark:text-red-400">
                    {error}
                </span>
            </div>
        )
    }

    return (
        <div className={`${divStyle} ` + (error && "error")}>
            <label
                htmlFor={name}
                className="mb-1 block">
                {label}{" "}
                <p className="inline text-[#EB0000]">{`${requiredMsg}`}</p>
            </label>

            {input}

            <span className="text-xs text-red-500 dark:text-red-400 md:text-sm">
                {error}
            </span>
        </div>
    )
}

export default Input
