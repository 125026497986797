import { createContext, useContext, useState } from "react"

interface Props {
    children: React.ReactNode
}

interface ITransfer {
    value: {
        email: string
        tickets: string[]
    }
    checked: Function
    setEmail: (value: string) => void
    reset: () => void
}

const initialValue = {
    value: {
        email: "",
        tickets: [],
    },
    checked: () => {},
    setEmail: () => {},
    reset: () => {},
}

const TransferContext = createContext<ITransfer>(initialValue)

const TransferProvider: React.FC<Props> = ({ children }) => {
    const [chosenTickets, setChosenTickets] = useState<string[]>([])
    const [email, setEmail] = useState("")

    const checked = (e: any) => {
        const value = e.target.value
        setChosenTickets((prev) =>
            chosenTickets.includes(value)
                ? prev.filter((cur) => cur !== value)
                : [...prev, e.target.value]
        )
    }

    const reset = () => {
        setChosenTickets([])
    }

    return (
        <TransferContext.Provider
            value={{
                value: { email: email, tickets: chosenTickets },
                checked,
                setEmail,
                reset,
            }}>
            {children}
        </TransferContext.Provider>
    )
}

export const useTransferContext = () => {
    return useContext(TransferContext)
}

export { TransferContext, TransferProvider }
