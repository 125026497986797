import axios from "axios"

export const uploadAvatar = async (updatedUser: any) => {
    const token = JSON.parse(
        localStorage.getItem("pass_ticket_token") || "null"
    )

    try {
        const { data } = await axios.post(
            process.env.NEXT_PUBLIC_API_URL + "users/avatar",
            updatedUser,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )

        return data
    } catch (err: any) {
        return err.response.data
    }
}
