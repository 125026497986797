import { useLang } from "hooks/useLang"
import { ChangeEvent, useEffect, useRef, forwardRef } from "react"

interface IOtp {
    otp: string[]
    setOtp: (value: string[]) => void
    isFilled?: boolean
    isError?: boolean
    width?: string
    height?: string
    disabled?: boolean
}

type InputElement = HTMLInputElement | null

const Otp = forwardRef<HTMLInputElement, IOtp>(
    (
        {
            otp,
            setOtp,
            isFilled,
            isError,
            width = "w-14",
            height = "h-14",
            disabled,
        },
        ref
    ) => {
        const { t, isAr } = useLang()
        const inputsRef = useRef<InputElement[]>([])

        const handleChange = (
            e: ChangeEvent<HTMLInputElement>,
            index: number
        ) => {
            const val = e.target.value

            if (!/^\d*$/.test(val)) return

            const newOtp = [...otp]

            if (val.length === 1) {
                newOtp[index] = val
                setOtp(newOtp)
                focusNextInput(index)
            } else if (val.length > 1) {
                const valArray = val.split("")
                for (
                    let i = index;
                    i < otp.length && i - index < valArray.length;
                    i++
                ) {
                    newOtp[i] = valArray[i - index]
                }
                setOtp(newOtp)
                const nextIndex = index + valArray.length - 1
                if (inputsRef.current[nextIndex]) {
                    inputsRef.current[nextIndex]?.focus()
                }
            } else {
                newOtp[index] = ""
                setOtp(newOtp)
                // Removed focusPreviousInput to prevent unwanted focus change
            }
        }

        const handlePaste = (
            e: React.ClipboardEvent<HTMLInputElement>,
            index: number
        ) => {
            e.preventDefault()
            const pasteData = e.clipboardData.getData("Text").trim()

            if (!/^\d+$/.test(pasteData)) return

            const pasteDigits = pasteData.split("").slice(0, otp.length - index)
            const newOtp = [...otp]

            for (let i = 0; i < pasteDigits.length; i++) {
                newOtp[index + i] = pasteDigits[i]
                if (inputsRef.current[index + i]) {
                    inputsRef.current[index + i]!.value = pasteDigits[i]
                }
            }

            setOtp(newOtp)

            // Focus the next input after the last pasted digit
            const nextIndex = index + pasteDigits.length
            if (inputsRef.current[nextIndex]) {
                inputsRef.current[nextIndex]?.focus()
            }
        }

        const handleKeyDown = (
            e: React.KeyboardEvent<HTMLInputElement>,
            index: number
        ) => {
            if (e.key === "Backspace" && otp[index] === "") {
                e.preventDefault()
                if (index > 0) {
                    const prevIndex = index - 1
                    inputsRef.current[prevIndex]?.focus()
                    const newOtp = [...otp]
                    newOtp[prevIndex] = ""
                    setOtp(newOtp)
                }
            }
        }

        const focusNextInput = (currentIndex: number) => {
            if (inputsRef.current[currentIndex + 1]) {
                inputsRef.current[currentIndex + 1]?.focus()
            }
        }

        useEffect(() => {
            if (inputsRef.current[0]) {
                inputsRef.current[0].focus()
            }
        }, [])

        return (
            <div>
                <div
                    className={`flex justify-center gap-x-2 ${
                        isAr && "flex-row-reverse"
                    }`}
                >
                    {otp.map((item, index) => (
                        <input
                            ref={(el) => {
                                inputsRef.current[index] = el
                            }}
                            className={`border bg-transparent text-center text-lg ${width} ${height} rounded-md ${
                                isError
                                    ? "errorStyle"
                                    : isFilled
                                      ? "border-green-500"
                                      : "verifyInput border-gray-200"
                            }`}
                            type="text"
                            name="otp"
                            // Remove or comment out the maxLength attribute
                            // maxLength={1}
                            key={index}
                            value={item || ""}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onFocus={(e) => e.target.select()}
                            onPaste={(e) => handlePaste(e, index)}
                            disabled={disabled}
                            inputMode="numeric"
                            pattern="\d*"
                        />
                    ))}
                </div>
            </div>
        )
    }
)

Otp.displayName = "Otp"

export default Otp
