import Loading from 'components/icons/Loading';
import { twMerge } from 'tailwind-merge';
import React, { forwardRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface IProps {
  prefixIcon?: React.ReactNode;
  text?: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  size?: 'small' | 'default' | 'large' | 'full' | 'search' | 'medium';
  theme?: 'primary' | 'alternate' | 'outline' | 'custom' | 'modern';
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  id?: string;
  href?: string;
  stopPropagationOnMouseDown?: boolean;
  stopPropagationOnClick?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      className = '',
      prefixIcon = null,
      text = '',
      children = null,
      suffixIcon = null,
      size = 'default',
      theme = 'primary',
      loading = false,
      disabled = false,
      type = 'button',
      id = '',
      onClick = () => {},
      href = '',
      stopPropagationOnClick,
      stopPropagationOnMouseDown,
    },
    ref
  ) => {
    const router = useRouter();

    const baseClasses = ['rounded-full', 'transition-all', 'duration-200', 'border', 'text-sm', 'base:text-base', 'flex', 'items-center', 'justify-center'];

    const themeClasses = {
      primary: 'bg-action-theme-400 hover:bg-action-theme-500 text-white border-transparent',
      modern: 'bg-[#1DC2AB] hover:opacity-80 text-white border-transparent',
      alternate: 'bg-action-theme-100 text-action-theme-400 border-action-theme-400 bg-opacity-20',
      outline: 'bg-transparent text-action-theme-400 border-action-theme-400',
      custom: className,
    };

    const disabledClasses = 'cursor-not-allowed bg-gray-400 pointer-events-none';

    const classNames = twMerge(...baseClasses, themeClasses[theme], className, (disabled || loading) && disabledClasses);

    const content = (
      <>
        {prefixIcon}
        {loading ? <Loading /> : text || children}
        {suffixIcon}
      </>
    );

    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      stopPropagationOnClick && e.stopPropagation();

      if (!disabled && !loading) {
        if (href) {
          router.push(href);
        } else {
          onClick(e);
        }
      }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
      stopPropagationOnMouseDown && e.stopPropagation();
    };

    if (href) {
      return (
        <Link
          href={href}
          className={classNames}
          id={id}
          onClick={(e) => handleClick(e)}
        >
          {content}
        </Link>
      );
    }

    return (
      <button
        type={type}
        disabled={disabled || loading}
        className={classNames}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        id={id}
        ref={ref}
      >
        {content}
      </button>
    );
  }
);

export default Button;
