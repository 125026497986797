import { DefaultSeoProps } from "next-seo"

export const ArSEO: DefaultSeoProps = {
    title: "باس تيكيتس",
    description:
        "منصة متكاملة لبيع التذاكر و إنشاء الفعاليات في أي مجال تتخيله؛ سواء كان رياضيًا، ثقافيًا، سياحيًا، اجتماعيًا، تعليميًا، موسيقيًا، ترفيهيًا.",
    openGraph: {
        type: "website",
        locale: "ar_SA",
        url: "www.pass-tickets.com",
        site_name: "باس تيكيتس",
        images: [
            {
                url: "https://passticketscdn.azureedge.net/frontend/hero.png",
                alt: "PassTickets",
                type: "image/png",
                height: 600,
                width: 800,
            },
            {
                url: "https://passticketscdn.azureedge.net/frontend/hero2.png",
                type: "image/png",
                alt: "PassTickets",
                height: 600,
                width: 800,
            },
        ],
    },
    languageAlternates: [
        {
            hrefLang: "x-default",
            href: "https://www.pass-tickets.com",
        },
        {
            hrefLang: "ar",
            href: "https://www.pass-tickets.com",
        },
        {
            hrefLang: "en",
            href: "https://www.pass-tickets.com/en-US",
        },
    ],
    canonical: process.env.NEXT_PUBLIC_SITE_URL || "https://www.pass-tickets.com",
    twitter: {
        cardType: "summary_large_image",
        handle: "@PassTickets",
        site: "pass-tickets.com",
    },
}

export const EnSEO = {
    title: "PassTickets",
    description:
        "A complete platform for selling tickets and creating events in any field you can imagine; Whether it is sports, cultural, tourist, social,...",
    openGraph: {
        type: "website",
        locale: "en_US",
        url: "https://www.pass-tickets.com/en-Us",
        site_name: "PassTickets",
    },
    canonical: process.env.NEXT_PUBLIC_SITE_URL || "https://www.pass-tickets.com",
    images: [
        {
            url: "https://www.passticketscdn.azureedge.net/frontend/hero.png",
            alt: "PassTickets",
            type: "image/png",
            height: 600,
            width: 800,
        },
        {
            url: "https://www.passticketscdn.azureedge.net/frontend/hero2.png",
            type: "image/png",
            alt: "PassTickets",
            height: 600,
            width: 800,
        },
    ],

    languageAlternates: [
        {
            hrefLang: "ar",
            href: "https://www.pass-tickets.com",
        },
        {
            hrefLang: "en",
            href: "https://www.pass-tickets.com/en-US",
        },
    ],

    twitter: {
        cardType: "summary_large_image",
        handle: "@PassTickets",
        site: "pass-tickets.com",
    },
}
