import { isCountCurrentlyQualifiedForPromotion } from "helpers/bookingv2/ticket"
import { useFeatureFlagPayload } from "posthog-js/react"
import {
    useReducer,
    Reducer,
    useContext,
    createContext,
    useEffect,
} from "react"
import { FeatureFlags } from "types/FeatureFlags"
import {
    ICartState,
    IReducerActions,
    ImprovedCartActionsEnum,
    SupportedPaymentMethods,
} from "types/improved-booking"

interface Props {
    children: React.ReactNode
}
type FeatureFlagPayload = {
    events?: string[]
}
export function serializeCartData(cartData) {
    return encodeURIComponent(JSON.stringify(cartData))
}

export function deserializeCartData(serializedCartData) {
    return JSON.parse(decodeURIComponent(serializedCartData))
}

const improvedInitialCartValues: ICartState = {
    event: "",
    total: 0,
    selectedTickets: [],
    selectedDays: null,
    selectedSeats: [],
    finalCart: [],
    coupon: undefined,
    guestData: undefined,
    bookingStep: "PrivacyPolicy",
    accreditation: null,
    payment_method: "card",
    user_type: "user",
    eventTitle: "",
    availablePaymentMethods: ["card", "tabby"],
}

const CartReducer: Reducer<ICartState, IReducerActions> = (state, action) => {
    switch (action.type) {
        case ImprovedCartActionsEnum.ADD_EVENT:
            return {
                ...state,
                event: action.payload.event,
            }
        case ImprovedCartActionsEnum.SET_EVENT_TITLE:
            return {
                ...state,
                eventTitle: action.payload,
            }
        case ImprovedCartActionsEnum.ADD_DAY:
            return {
                ...state,
                selectedDays: action.payload,
                selectedTickets: [], // Clear tickets when day is changed
            }

        case ImprovedCartActionsEnum.ADD_TICKET: {
            const ticketType = action.payload
            const currentTicketCount = state.selectedTickets.filter(
                (ticket) => ticket.type_id === ticketType.type_id
            ).length

            const isPromotionApplicable =
                ticketType.promotion &&
                isCountCurrentlyQualifiedForPromotion(
                    ticketType,
                    currentTicketCount
                )

            if (isPromotionApplicable) {
                const freeTicket = {
                    ...ticketType,
                    is_promotion_free: true,
                    price: 0,
                }

                return {
                    ...state,
                    selectedTickets: [...state.selectedTickets, freeTicket],
                }
            } else {
                return {
                    ...state,
                    selectedTickets: [...state.selectedTickets, ticketType],
                }
            }
        }

        case ImprovedCartActionsEnum.ADD_ALL_SELECTED_TICKETS:
            return {
                ...state,
                selectedTickets: action.payload,
            }
        case ImprovedCartActionsEnum.REMOVE_DAY:
            return {
                ...state,
                selectedDays: null,
            }
        case ImprovedCartActionsEnum.ADD_SEATS: {
            const check = state.selectedSeats.find(
                (seat) => seat.id === action.payload.id
            )

            if (check) {
                return {
                    ...state,
                    selectedSeats: state.selectedSeats.filter(
                        (seat) => seat.id !== action.payload.id
                    ),
                }
            } else {
                return {
                    ...state,
                    selectedSeats: [...state.selectedSeats, action.payload],
                }
            }
        }
        case ImprovedCartActionsEnum.ADD_COUPON:
            return {
                ...state,
                coupon: action.payload,
            }
        case ImprovedCartActionsEnum.REMOVE_COUPON:
            return {
                ...state,
                coupon: undefined,
            }
        case ImprovedCartActionsEnum.REMOVE_TICKETS:
            return {
                ...state,
                selectedTickets: [],
            }
        case ImprovedCartActionsEnum.REMOVE_TICKET: {
            const id = [...state.selectedTickets]
                .reverse()
                .findIndex((t) => t.type_id === action.payload.type_id)

            if (id === -1) {
                return state
            }

            const reverseIndex = state.selectedTickets.length - 1 - id
            const newTickets = [...state.selectedTickets]
            newTickets.splice(reverseIndex, 1)

            return {
                ...state,
                selectedTickets: newTickets,
            }
        }

        case ImprovedCartActionsEnum.REMOVE_SEAT:
            return {
                ...state,
                selectedSeats: state.selectedSeats.filter(
                    (seat) => seat.id !== action.payload.id
                ),
            }
        case ImprovedCartActionsEnum.REMOVE_SEATS:
            return {
                ...state,
                selectedSeats: [],
            }
        case ImprovedCartActionsEnum.CLEAR_CART:
            return { ...state, ...improvedInitialCartValues }
        case ImprovedCartActionsEnum.SET_GUEST_DATA:
            return {
                ...state,
                guestData: action.payload,
            }
        case ImprovedCartActionsEnum.SET_BOOKING_STEP:
            return {
                ...state,
                bookingStep: action.payload,
            }
        case ImprovedCartActionsEnum.SET_ACCREDITATION_DATA:
            return {
                ...state,
                accreditation: action.payload,
            }

        case ImprovedCartActionsEnum.SET_PAYMENT_METHOD:
            return {
                ...state,
                payment_method: action.payload,
            }
        case ImprovedCartActionsEnum.REMOVE_PAYMENT_METHOD_ACTION:
            return {
                ...state,
                payment_method: "card",
            }

        case ImprovedCartActionsEnum.SET_USER_TYPE:
            return {
                ...state,
                user_type: action.payload,
            }

        case ImprovedCartActionsEnum.SET_AVAILABLE_PAYMENT_METHODS_ACTION: {
            return {
                ...state,
                // available_payment_methods: action.payload,
                availablePaymentMethods: action.payload,
            }
        }
        case ImprovedCartActionsEnum.REMOVE_AVAILABLE_PAYMENT_METHODS_ACTION: {
            const availablePaymentMethods =
                state.availablePaymentMethods.filter(
                    (method) => method !== action.payload
                )
            return {
                ...state,
                availablePaymentMethods: availablePaymentMethods,
                payment_method: availablePaymentMethods.includes(
                    state.payment_method!
                )
                    ? state.payment_method
                    : "card",
            }
        }
        default:
            return state
    }
}
export const CartDispatchContext = createContext<
    React.Dispatch<IReducerActions>
>((action: IReducerActions) => action)

export const CartContext = createContext<ICartState>(improvedInitialCartValues)

export const CartProvider: React.FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        CartReducer,
        improvedInitialCartValues,
        (initial) => {
            if (typeof window !== "undefined") {
                const storedCart = localStorage.getItem("pass_ticket_cart")
                if (storedCart) {
                    return JSON.parse(storedCart)
                } else {
                    localStorage.setItem(
                        "pass_ticket_cart",
                        JSON.stringify(initial)
                    )
                    return initial
                }
            }
            return initial
        }
    )

    const featureFlagPayload = useFeatureFlagPayload(
        FeatureFlags.TABBY
    ) as FeatureFlagPayload

    const flagEvents = featureFlagPayload?.events || []

    const isTabbySupported = flagEvents.includes(state.event_id!)

    const availablePaymentMethods = () => {
        if (isTabbySupported) {
            return ["card", "tabby"]
        }
        return ["card"]
    }

    useEffect(() => {
        dispatch({
            type: ImprovedCartActionsEnum.SET_AVAILABLE_PAYMENT_METHODS_ACTION,
            payload: availablePaymentMethods() as SupportedPaymentMethods[],
        })
    }, [isTabbySupported])

    useEffect(() => {
        if (typeof window !== "undefined") {
            localStorage.setItem("pass_ticket_cart", JSON.stringify(state))
        }
    }, [state])

    return (
        <CartContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>
                {children}
            </CartDispatchContext.Provider>
        </CartContext.Provider>
    )
}

export const useCartState = () => {
    const context = useContext(CartContext)
    if (context === undefined) {
        throw new Error("useCartState must be used within a CartProvider")
    }
    return context
}
export const useCartDispatch = () => {
    const context = useContext(CartDispatchContext)
    if (context === undefined) {
        throw new Error("useCartDispatch must be used within a CartProvider")
    }
    return context
}
