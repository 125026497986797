import { getCookie } from "helpers/cookies/getCookie"
import { useState, useEffect } from "react"

export const useVerification = () => {
    const [verifyCookie, setVerifyCookie] = useState<boolean>(false)

    useEffect(() => {
        if (typeof window !== "undefined") {
            const cookie = getCookie("verifyNotification")
            setVerifyCookie(!!cookie)
        }
    }, [])

    return { verifyCookie, setVerifyCookie }
}
