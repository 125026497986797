import { useEffect, useState } from "react"

const useScrollTop = () => {
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(() => {
        const onScroll = () => {
            setScrollTop(
                window.pageYOffset || document.documentElement.scrollTop
            )
        }

        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return scrollTop
}

export default useScrollTop
