import React, { useEffect, useRef } from "react"

interface IProps {
    className?: string
    children?: React.ReactNode
    isOpen?: boolean
    Close?: Function
    closeOnOutsideClick?: boolean
}

export default function Modal({
    className = "",
    children,
    isOpen = false,
    Close = () => {},
    closeOnOutsideClick = true,
}: IProps) {
    const modalRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const disableBodyScroll = (e: TouchEvent) => {
            if (
                modalRef.current &&
                modalRef.current.contains(e.target as Node)
            ) {
                return
            }
            e.preventDefault()
        }

        if (isOpen) {
            document.body.style.overflow = "hidden"
            document.body.addEventListener("touchmove", disableBodyScroll, {
                passive: false,
            })
        } else {
            document.body.style.overflow = ""
        }

        return () => {
            document.body.style.overflow = ""
            document.body.removeEventListener("touchmove", disableBodyScroll)
        }
    }, [isOpen])

    return !isOpen ? null : (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-lg flex justify-center items-center z-50 ${
                closeOnOutsideClick ? "cursor-pointer" : "cursor-default"
            }`}
            onClick={(e) => closeOnOutsideClick && Close(e)}
        >
            <div
                className={"Modal cursor-default overflow-auto " + className}
                onClick={(e) => e.stopPropagation()}
                ref={modalRef}
            >
                {children}
            </div>
        </div>
    )
}
