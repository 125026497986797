import CloseIcon from "components/icons/CloseIcon"
import { setCookie } from "helpers/cookies/setCookie"
import { useLang } from "hooks/useLang"
import React from "react"

interface IProps {
    setOpenModal: (value: boolean) => void
    setShowNotification: (value: boolean) => void
    isEmailVerified: boolean | any
    isPhoneVerified: boolean | any
}

const VerifyNotification = ({
    setOpenModal,
    setShowNotification,
    isEmailVerified,
    isPhoneVerified,
}: IProps) => {
    const { t } = useLang()

    const changeColor = () => {
        if (!isEmailVerified && !isPhoneVerified) {
            return "bg-red-200 border-red-500 text-red-500"
        }
        if (
            (isEmailVerified && !isPhoneVerified) ||
            (!isEmailVerified && isPhoneVerified)
        ) {
            return "bg-orange-200 border-orange-500 text-orange-500"
        }
        return false
    }

    const changeText = () => {
        if (!isEmailVerified && !isPhoneVerified) {
            return t("verify_your_account")
        }
        if (isEmailVerified && !isPhoneVerified) {
            return t("verify_your_phone")
        }
        if (!isEmailVerified && isPhoneVerified) {
            return t("verify_your_email")
        }
    }

    const handleClose = () => {
        setCookie("verifyNotification", "expired verify notification", 24) // Expire after 24 Hour
        setShowNotification(false)
    }

    return (
        <div
            className={`fixed top-[70px] z-50 flex-row-reverse flex h-[40px] w-full items-center justify-between border-2 bg-opacity-80 text-xs font-medium backdrop-blur-md sm:text-sm ${changeColor()}`}>
            <div className="flex items-center justify-between layout">
                <div className="flex items-center gap-2">
                    <span
                        className={`flex h-6 w-6 items-center justify-center rounded-full border-2 text-xs ${changeColor()}`}>
                        !
                    </span>
                    <p className="capitalize">{changeText()}</p>
                </div>
                <div className="flex items-center gap-2 md:gap-4">
                    <p
                        className="underline capitalize cursor-pointer"
                        onClick={() => setOpenModal(true)}>
                        {t("click_here")}
                    </p>
                    <div
                        onClick={handleClose}
                        className="p-1 rounded-full cursor-pointer">
                        <CloseIcon className="w-5 h-5" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyNotification
