import React from "react"
import Button from "../Form/Button"
import { MainLayout } from "components/Layout"

import Modal from "../Modal/Modal"
import ErrorImage from "../../../public/svg/ErrorImage"
import { useLang } from "hooks/useLang"

const ErrorMessage = ({ onClick }: any) => {
    const { t } = useLang()

    return (
        <div className="flex flex-col items-center justify-center gap-5 p-8 sm:p-11">
            <ErrorImage />
            <h2 className="text-center text-2xl font-bold text-primary-theme-400 dark:text-white">
                {t("ops")}
            </h2>

            <p>{t("something_went_wrong")}</p>

            <Button
                //size="small"
                text={t("click_here")}
                //className="self-start text-sm capitalize"
                className="w-3/6 rounded-full px-4 py-3 text-sm font-bold text-white transition-all md:px-6"
                onClick={onClick}
            />
        </div>
    )
}

class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props)

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false, isOpen: false }
    }
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI

        return { hasError: true }
    }
    componentDidCatch(error: Error, errorInfo: any) {
        // You can use your own error logging service here
        console.log({ error, errorInfo })
    }

    render() {
        // Check if the error is thrown
        if (this.state.hasError == true) {
            // You can render any custom fallback UI
            return (
                <>
                    <MainLayout>
                        <Modal
                            className="w-[90%] shadow-md dark:bg-primary-theme-500 sm:w-auto"
                            Close={() => this.setState({ isOpen: false })}
                            isOpen={true}>
                            <ErrorMessage
                                onClick={() => {
                                    localStorage.clear()
                                    window.location.href = "/"
                                    this.setState({ hasError: false })
                                }}
                            />
                        </Modal>
                    </MainLayout>
                </>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
