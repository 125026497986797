import Modal from "components/Modal/Modal"
import React, { createContext, useContext, useState } from "react"

const initialValue = {
    isOpen: false,
    openModal: (content: any) => {
        return
    },
    closeModal: () => {
        return
    },
    modalContent: null,
}

const ModalContext = createContext(initialValue)

export const useModal = () => {
    return useContext(ModalContext)
}

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [modalContent, setModalContent] = useState(null)

    const openModal = (content) => {
        setModalContent(content)
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
        setModalContent(null)
    }

    return (
        <ModalContext.Provider
            value={{ isOpen, openModal, closeModal, modalContent }}
        >
            {children}
            {isOpen && (
                <Modal
                    children={modalContent}
                    isOpen={isOpen}
                    Close={closeModal}
                />
            )}
        </ModalContext.Provider>
    )
}

export const useModalContext = () => {
    return useContext(ModalContext)
}
