import axios from "axios"

export const login = async (userData: any) => {
    try {
        const { data } = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}users/signin`,
            userData
        )

        return data
    } catch (err: any) {
        return err.response.data
    }
}
