import { useRouter } from "next/router"
import Script from "next/script"
import React from "react"
interface Props {
    children: React.ReactNode
}
const TikTokPixelIntegration = ({ children }: Props) => {
    const router = useRouter()
  
    const currentEventId = router.pathname.includes("guvali")
        ? process.env.NEXT_PUBLIC_CURRENT_GUVALI_ID
        : (router?.query?.eventid as string)
    const mainPixel = process.env.NEXT_PUBLIC_PASSTICKETS_TIKTOK_PIXEL_KEY
    const integrationsData = [
        {
            eventName: "guvali",
            eventId: process.env.NEXT_PUBLIC_CURRENT_GUVALI_ID,
            pixelId: process.env.NEXT_PUBLIC_GUVALI_TIKTOK_PIXEL_KEY,
        },
        {
            eventName: "billory",
            eventId: "66eaa9f10c3c0a69b8ab51c9",
            pixelId: process.env.NEXT_PUBLIC_BILLORY_TIKTOK_PIXEL_KEY,
        },
    ]

    const eventData = integrationsData.find(
        (integration) => integration?.eventId === currentEventId
    )
    return (
        <>
            {/* Main Pixel */}
            <Script id="tiktok-pixel-main" strategy="afterInteractive">
                {`!function (w, d, t) {
                    w.TiktokAnalyticsObject=t;
                    var ttq=w[t]=w[t]||[];
                    ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
                    ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
                    for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
                    ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e};
                    ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
                    ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
                    var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
                    var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    ttq.load('${mainPixel}');
                    ttq.page();
                }(window, document, 'ttq');`}
            </Script>
            {/* Event-specific Pixel */}
            {eventData?.pixelId && (
                <Script id="tiktok-pixel-event" strategy="afterInteractive">
                    {`ttq.load('${eventData.pixelId}');
                      ttq.page();`}
                </Script>
            )}
            {children}
        </>
    )
}
export default TikTokPixelIntegration
