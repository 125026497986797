import { useRouter } from "next/router"
import { useEffect } from "react"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

if (typeof window !== "undefined") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host:
            process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
        person_profiles: "identified_only",
        // Enable debug mode in development
        // loaded: (posthog) => {
        //     // if (
        //     //     process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT === "development" ||
        //     //     process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT === "local"
        //     // )
        //     //     posthog.debug()
        // },
    })
}
const FeatureFlagProvider = ({ children }) => {
    const router = useRouter()

    useEffect(() => {
        // Track page views
        const handleRouteChange = () => posthog?.capture("$pageview")
        router.events.on("routeChangeComplete", handleRouteChange)

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [])

    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export default FeatureFlagProvider
