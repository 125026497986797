import { useEffect, useState } from "react"

function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        width: number
        height: number
    }>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        if (typeof window === "undefined") return

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const isMobile = windowSize.width < 768
    const isTablet = windowSize.width >= 768 && windowSize.width < 1024
    const isDesktop = windowSize.width >= 1280

    return {
        width: windowSize.width,
        height: windowSize.height,
        isMobile,
        isTablet,
        isTabletOrSmaller: windowSize.width < 1024,
        isDesktop,
    }
}

export default useWindowSize
