import { useTheme } from "next-themes"
import * as React from "react"

const RoundedXIcon = (props: React.SVGProps<SVGSVGElement>) => {
    const { theme } = useTheme()
    const light = theme === "light"

    return (
        <svg
            width={21}
            height={21}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M13.886 6.827a.999.999 0 0 0-1.42 0l-2.29 2.3-2.29-2.3a1.004 1.004 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42.998.998 0 0 0 1.42 0l2.29-2.3 2.29 2.3a.999.999 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-2.3-2.29 2.3-2.29a1 1 0 0 0 0-1.42Zm3.36-3.36a10 10 0 1 0-14.14 14.14 10 10 0 1 0 14.14-14.14Zm-1.41 12.73a8 8 0 1 1 2.34-5.66 7.949 7.949 0 0 1-2.34 5.66Z"
                fill={light ? "#222" : "#fff"}
            />
        </svg>
    )
}

export default RoundedXIcon
