import { useLang } from "hooks/useLang"
import React, { useState, useEffect } from "react"

interface ITime {
    initialMinute: number
    initialSeconds: number
    setShowResend: (value: boolean) => void
    hideText?: boolean
    center?: boolean
    color?: string
}

const Timer = React.memo(
    ({
        initialMinute = 0,
        initialSeconds = 0,
        setShowResend,
        hideText,
        center,
        color = "rose-600",
    }: ITime) => {
        const [minutes, setMinutes] = useState(initialMinute)
        const [seconds, setSeconds] = useState(initialSeconds)
        const { t } = useLang()

        useEffect(() => {
            const myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((sec: number) => sec - 1)
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                        setShowResend(true)
                    } else {
                        setMinutes((sec: number) => sec - 1)
                        setSeconds(59)
                    }
                }
            }, 1000)
            return () => {
                clearInterval(myInterval)
            }
        })

        return (
            <div className={center ? `flex items-center justify-center` : ""}>
                <div
                    className={`flex items-center gap-2 text-sm text-${color}`}
                >
                    {hideText ? null : <span>{t("time_left")}</span>}
                    <span>
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                </div>
            </div>
        )
    }
)

export default Timer

Timer.displayName = "Timer"
