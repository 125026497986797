import axios from "axios"

export const updateUser = async (updatedUser: any) => {
    const token = JSON.parse(
        localStorage.getItem("pass_ticket_token") || "null"
    )

    try {
        const { data } = await axios.put(
            process.env.NEXT_PUBLIC_API_URL + "users",
            updatedUser,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )

        return data
    } catch (err: any) {
        if (err.response.data.errors) {
            throw err.response.data.errors[0].split(`"`)[1]
        } else {
            return err.response.data
        }
    }
}
