import * as React from "react"

const ErrorImage = () => (
    <svg
        width="346"
        height="297"
        viewBox="0 0 346 297"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M131.395 254.022C130.406 240.048 156.922 192.28 198.187 189.784C243.395 187.049 190.114 152.222 196.171 146.89C197.593 145.638 199.273 143.526 198.211 141.956C182.66 118.992 187.297 72.7439 162.979 53.876C128.437 27.0758 109.232 59.5703 75.297 50.5486C46.3559 42.8547 16.4634 102.212 27.9766 134.983L131.395 254.022Z"
            fill="#D7E8F8"
            fill-opacity="0.3"
        />
        <path
            d="M165.406 62.5757C169.034 77.8063 148.467 135.301 103.412 145.548C52.8797 157.041 122.293 186.882 112.895 192.946C111.303 193.974 110.277 196.456 111.602 197.81C133.344 220.042 136.456 272.525 166.921 289.036C209.881 312.319 225.159 272.996 264.222 276.783C297.537 280.012 319.724 209.123 301.075 175.073L165.406 62.5757Z"
            fill="#D7E8F8"
            fill-opacity="0.3"
        />
        <path
            d="M167.947 221.286C178.027 212.369 229.947 204.724 255.953 234.789C284.178 267.419 279.97 207.819 287.281 208.17C289.174 208.26 291.906 208.456 292.516 206.661C301.088 181.449 338.859 158.026 339.046 128.511C339.311 86.4699 303.31 90.7788 290.37 59.5852C279.334 32.9824 216.602 44.8165 198.332 72.7532L167.947 221.286Z"
            fill="#D7E8F8"
            fill-opacity="0.3"
        />
        <path
            d="M286.488 269.681C295.871 269.61 282.784 187.429 320.21 138.697C366.492 78.4345 273.433 73.401 272.479 73.4696C271.525 73.5382 178.95 17.2929 130.965 26.8093C67.2745 39.4411 84.9545 92.6618 41.7704 121.942C4.94176 146.914 51.1154 254.72 99.0475 273.746C99.0475 273.746 277.106 269.751 286.488 269.681Z"
            fill="#DED6F8"
        />
        <path
            d="M141.689 72.505L121.593 87.3793L123.13 87.7324L141.689 90.2012V72.505"
            fill="#877FB6"
        />
        <path
            d="M226.074 220.738H120.039V92.7867L144.042 95.9795C144.161 95.9974 144.281 96.0025 144.4 96.0025C145.053 96.0025 145.688 95.7672 146.186 95.3322C146.774 94.818 147.111 94.0761 147.111 93.2958V70.9153H226.074V220.738ZM121.593 87.3783L141.689 72.5041V90.2002L123.13 87.7314L121.422 87.5063L121.593 87.3783ZM227.743 65.4967H143.146C140.019 66.8348 116.994 84.3902 114.617 87.2658V222.401C114.617 224.47 116.301 226.156 118.371 226.156H227.743C229.812 226.156 231.496 224.47 231.496 222.401V69.2524C231.496 67.1827 229.812 65.4967 227.743 65.4967Z"
            fill="#61549E"
        />
        <path
            d="M157.304 115.818L153.531 112.413L157.303 109.005C158.044 108.337 158.102 107.194 157.432 106.454C156.763 105.715 155.62 105.656 154.88 106.324L150.836 109.977L146.792 106.324C146.051 105.656 144.908 105.715 144.239 106.454C143.57 107.194 143.628 108.337 144.368 109.005L148.14 112.413L144.368 115.818C143.628 116.488 143.57 117.629 144.239 118.369C144.596 118.763 145.087 118.965 145.58 118.965C146.012 118.965 146.446 118.811 146.792 118.499L150.836 114.846L154.88 118.499C155.225 118.811 155.659 118.965 156.091 118.965C156.584 118.965 157.075 118.763 157.432 118.369C158.102 117.629 158.044 116.488 157.304 115.818"
            fill="#61549E"
        />
        <path
            d="M203.104 114.367L199.33 110.959L203.104 107.551C203.843 106.884 203.9 105.743 203.232 105.003C202.561 104.261 201.419 104.205 200.679 104.873L196.634 108.526L192.591 104.873C191.851 104.205 190.707 104.261 190.039 105.003C189.368 105.743 189.427 106.884 190.167 107.551L193.94 110.959L190.167 114.367C189.427 115.035 189.368 116.178 190.039 116.918C190.394 117.312 190.886 117.514 191.38 117.514C191.813 117.514 192.246 117.358 192.591 117.045L196.634 113.395L200.679 117.045C201.025 117.358 201.457 117.514 201.89 117.514C202.384 117.514 202.876 117.312 203.232 116.918C203.9 116.178 203.843 115.035 203.104 114.367"
            fill="#61549E"
        />
        <path
            d="M153.84 163.66C154.921 164.788 156.19 165.336 157.717 165.336C160.611 165.336 162.132 164.059 163.144 160.787C164.244 157.226 164.512 151.828 164.851 144.992C164.925 143.498 165 141.97 165.089 140.379C164.441 140.545 163.792 140.727 163.142 140.927C161.693 141.372 160.319 141.886 159.027 142.436C159.086 142.608 159.121 142.789 159.126 142.978L159.518 158.334C159.544 159.331 158.756 160.16 157.758 160.186C157.742 160.186 157.726 160.186 157.711 160.186C156.734 160.186 155.93 159.408 155.905 158.426L155.539 144.104C153.194 145.348 151.255 146.634 149.806 147.696C149.769 151.605 150.403 160.076 153.84 163.66"
            fill="#877FB6"
        />
        <path
            d="M164.85 144.993C164.512 151.829 164.244 157.227 163.143 160.788C162.132 164.06 160.61 165.337 157.717 165.337C156.189 165.337 154.921 164.789 153.84 163.661C150.403 160.077 149.768 151.606 149.806 147.697C151.254 146.635 153.194 145.348 155.539 144.105L155.905 158.427C155.93 159.409 156.734 160.187 157.71 160.187C157.726 160.187 157.742 160.187 157.758 160.187C158.755 160.161 159.544 159.332 159.518 158.335L159.126 142.979C159.121 142.79 159.086 142.608 159.027 142.437C160.319 141.887 161.693 141.373 163.142 140.928C163.792 140.728 164.441 140.546 165.088 140.38C165 141.971 164.924 143.499 164.85 144.993ZM203.05 147.121C195.953 141.186 188.441 137.507 180.727 136.182C174.526 135.118 168.205 135.568 161.935 137.52C151.323 140.823 144.604 147.242 144.323 147.513C143.607 148.206 143.588 149.347 144.281 150.063C144.635 150.432 145.108 150.616 145.581 150.616C145.813 150.616 146.045 150.57 146.264 150.483C146.319 151.55 146.416 152.778 146.583 154.083C147.289 159.614 148.851 163.676 151.227 166.156C153.002 168.008 155.186 168.949 157.717 168.949C162.217 168.949 165.122 166.629 166.597 161.855C167.831 157.859 168.11 152.261 168.461 145.172C168.548 143.389 168.64 141.557 168.75 139.633C179.803 137.932 190.535 141.37 200.73 149.892C201.496 150.532 202.638 150.429 203.278 149.664C203.918 148.899 203.815 147.761 203.05 147.121Z"
            fill="#61549E"
        />
        <path
            d="M236.624 250.246L113.201 237.377C108.755 236.913 105.551 232.897 106.088 228.459L111.77 181.487C112.295 177.152 116.199 174.038 120.542 174.491L236.139 186.544C240.585 187.007 243.788 191.024 243.251 195.461L236.624 250.246"
            fill="white"
        />
        <path
            d="M118.67 144.758C118.08 143.955 116.948 143.781 116.144 144.372C115.95 144.515 111.339 147.925 106.429 153.802C103.538 157.261 101.143 160.863 99.3105 164.509C96.9996 169.106 95.581 173.783 95.0937 178.416C94.0676 188.166 97.1086 197.69 104.138 206.775C104.128 206.923 104.12 207.074 104.12 207.225C104.12 210.705 106.942 213.521 110.422 213.521C113.902 213.521 116.723 210.705 116.723 207.225C116.723 203.749 113.902 200.929 110.422 200.929C108.594 200.929 106.949 201.707 105.798 202.95C100.218 195.124 97.8278 187.027 98.6863 178.815C99.6812 169.298 104.919 161.262 109.138 156.196C113.774 150.629 118.241 147.314 118.285 147.283C119.089 146.692 119.261 145.561 118.67 144.758"
            fill="#61549E"
        />
        <path
            d="M250.906 178.416C250.417 173.783 248.998 169.106 246.689 164.509C244.856 160.863 242.462 157.261 239.569 153.802C234.661 147.925 230.05 144.515 229.855 144.372C229.052 143.781 227.92 143.955 227.328 144.758C226.737 145.561 226.911 146.692 227.715 147.283C227.759 147.316 232.165 150.573 236.796 156.117C241.039 161.193 246.305 169.249 247.311 178.792C248.118 186.454 246.095 194.014 241.297 201.346C240.596 201.078 239.835 200.929 239.039 200.929C235.557 200.929 232.736 203.749 232.736 207.225C232.736 210.705 235.557 213.521 239.039 213.521C242.518 213.521 245.34 210.705 245.34 207.225C245.34 205.862 244.905 204.598 244.165 203.567C249.546 195.457 251.812 187.025 250.906 178.416Z"
            fill="#61549E"
        />
        <path
            d="M137.735 218.747L128.557 217.719C126.86 217.53 125.402 216.772 125.612 214.902L127.797 195.418C127.933 194.208 129.215 193.09 130.739 193.258L140.125 194.31C141.441 194.458 142.433 195.62 142.297 196.833C142.157 198.078 140.948 198.854 139.632 198.705L132.428 197.899L131.803 203.471L138.349 204.203C139.561 204.339 140.634 205.406 140.494 206.651C140.355 207.897 139.207 208.749 137.856 208.598L131.311 207.867L130.678 213.508L138.228 214.352C139.509 214.495 140.544 215.593 140.412 216.77C140.272 218.016 139.051 218.896 137.735 218.747"
            fill="#392986"
        />
        <path
            d="M153.901 200.304L150.23 199.892L149.586 205.638L153.464 206.07C155.854 206.339 157.248 205.479 157.449 203.678C157.613 202.225 157.052 200.657 153.901 200.304ZM156.739 210.327L160.685 217.987C161.633 219.847 159.731 221.489 158.172 221.315C157.306 221.218 156.622 220.758 156.249 220.013L151.452 210.261L149.096 209.997L148.208 217.923C148.072 219.133 146.809 220.08 145.493 219.931C144.211 219.788 143.219 218.627 143.359 217.381L145.594 197.446C145.746 196.095 147.086 195.09 148.506 195.248L154.532 195.924C161.043 196.653 162.706 200.588 162.298 204.223C161.914 207.649 159.826 209.693 156.739 210.327"
            fill="#392986"
        />
        <path
            d="M174.854 202.651L171.182 202.239L170.539 207.985L174.418 208.42C176.807 208.686 178.2 207.827 178.402 206.028C178.566 204.572 178.005 203.004 174.854 202.651ZM177.693 212.675L181.638 220.337C182.585 222.194 180.683 223.839 179.124 223.663C178.259 223.566 177.575 223.105 177.201 222.363L172.403 212.608L170.051 212.345L169.16 220.27C169.024 221.48 167.762 222.427 166.445 222.279C165.164 222.135 164.172 220.974 164.312 219.728L166.547 199.793C166.699 198.443 168.038 197.437 169.459 197.596L175.486 198.271C181.997 199 183.658 202.935 183.251 206.57C182.867 209.996 180.78 212.04 177.693 212.675"
            fill="#392986"
        />
        <path
            d="M191.196 208.895L190.428 215.746C190.118 218.517 191.216 220.602 194.055 220.919C196.897 221.236 198.431 219.445 198.741 216.677L199.509 209.826C199.824 207.022 198.925 205.029 195.876 204.689C192.829 204.346 191.511 206.091 191.196 208.895V208.895ZM185.579 215.204L186.349 208.352C186.9 203.438 189.893 199.567 196.37 200.294C202.847 201.018 204.908 205.454 204.358 210.368L203.59 217.22C203.021 222.272 199.555 225.985 193.564 225.314C187.573 224.644 185.013 220.256 185.579 215.204"
            fill="#392986"
        />
        <path
            d="M217.106 207.381L213.434 206.972L212.792 212.715L216.67 213.15C219.059 213.419 220.454 212.557 220.657 210.758C220.818 209.305 220.257 207.734 217.106 207.381ZM219.945 217.407L223.89 225.067C224.84 226.924 222.935 228.57 221.379 228.396C220.513 228.298 219.827 227.835 219.456 227.093L214.658 217.341L212.303 217.077L211.414 225.001C211.278 226.213 210.014 227.157 208.698 227.009C207.418 226.866 206.424 225.704 206.565 224.458L208.8 204.524C208.951 203.175 210.293 202.167 211.711 202.328L217.738 203.001C224.249 203.73 225.913 207.668 225.503 211.301C225.119 214.726 223.033 216.77 219.945 217.407"
            fill="#392986"
        />
    </svg>
)

export default ErrorImage
